import {
  createComponentAdmin,
  PublishesTo,
  SubscribesTo,
  StringEnum,
  type SchemaTypeHelper,
} from '@backstage-components/base';
import {Type, type Static} from '@sinclair/typebox';

const reactName = 'DisneyOneidAuth';
const name = 'Disney OneID Authentication';
const description = 'Allow guests to authenticate with Disney OneID';

const schema = Type.Object({
  disneyClientId: Type.String({
    title: 'Disney Client ID',
    description: 'The Disney Client ID',
    default: 'DTSS-DEV.BRANDED.C.WEB',
  }),
  // The values for `oneidEnvironment` are tightly coupled to the GQL resolver
  // implementation for `verifyExternalGuest`. Changes to the allowed values
  // will require changes to the resolver be made in a backwards compatible way
  // (because some data with the old structure may already be published)
  oneidEnvironment: Type.Optional(
    StringEnum(['QA', 'Staging', 'Production'] as const, {
      title: 'OneID Environment',
      description:
        'The OneID Environment, defines the URL for the OneID JS SDK (https://{qa.,stg.,}cdn.registerdisney.go.com/v4/OneID.js)',
      default: 'QA',
    })
  ),
});

const privateSchema = Type.Object({
  // The schema for `requiredMembershipLevel` is tightly coupled to the GQL
  // resolver implementation for `verifyExternalGuest`. Changes to the schema
  // will require changes to the resolver be made in a backwards compatible way
  // (because some data with the old structure may already be published)
  membershipRequirement: Type.Optional(
    StringEnum(['None', 'Free', 'D23 Gold'] as const, {
      title: 'Membership Requirement for Authentication',
      description:
        'Optionally restrict guests to only those with the indicated membership or higher. None requires only the SWID is valid, Free means signup was completed. Higher "tiers" will also work for lower requirements.',
      default: 'None',
    })
  ),
});

const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'General',
                ['disneyClientId', 'oneidEnvironment', 'membershipRequirement'],
              ],
            ],
          },
        ],
      ],
      ['Styling', []],
      ['Animations', []],
    ],
  },
};

type DefaultFieldData = Static<typeof schema>;

const defaultFieldData: DefaultFieldData = {
  disneyClientId: schema.properties.disneyClientId.default,
  oneidEnvironment: schema.properties.oneidEnvironment.default,
};

const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:launch-login`,
    description:
      'Launches the UI for the standard login (email/password) workflow for Disney OneID authentication',
    options: {title: 'Disney OneId: Launch Login'},
  }),
  SubscribesTo({
    topic: `${reactName}:launch-register`,
    description: 'Launches the UI for the register workflow for Disney OneID',
    options: {title: 'Disney OneId: Launch Register'},
  }),
  PublishesTo({
    topic: `${reactName}:on-error`,
    description: 'Indicates an error in the Disney OneID module',
    meta: {
      reason: Type.String(),
      step: Type.Union([
        Type.Literal('launchLogin'),
        Type.Literal('launchRegistration'),
      ]),
    },
    options: {'$lcd-flow-ignore': true},
  }),
  PublishesTo({
    topic: `${reactName}:on-init`,
    description: 'Indicates Disney OneID authentication has been initialized',
    meta: {
      isLoggedIn: Type.String(),
    },
    options: {title: 'Disney OneId: On Initialization'},
  }),
  PublishesTo({
    topic: `${reactName}:on-init-error`,
    description: 'Indicates Disney OneID failed to initialize',
    meta: {
      reason: Type.String(),
      step: Type.Union([
        Type.Literal('init'),
        Type.Literal('getGuest'),
        Type.Literal('getGuestPostInit'),
      ]),
    },
    options: {title: 'Disney OneId: On Initialization Error'},
  }),
  PublishesTo({
    topic: `${reactName}:on-launch-login`,
    description: 'Indicates the login window has been launched',
    options: {title: 'Disney OneId: On Login Opened'},
  }),
  PublishesTo({
    topic: `${reactName}:on-launch-register`,
    description: 'Indicates the login window has been launched',
    options: {title: 'Disney OneId: On Register Opened'},
  }),
  // #region Authentication
  PublishesTo({
    topic: `${reactName}:verify`,
    description: 'Indicates an authenticated guest has been provided via OneId',
    meta: {
      email: Type.Optional(Type.String()),
      showId: Type.String(),
      moduleId: Type.String(),
      name: Type.String(),
      swid: Type.String(),
      /**
       * Whether the guest should be re-authenticated if they are the logged in
       * guest. Because `init` triggers a `:verify`, every time the page loads
       * another `:verify` instruction is sent. `shouldReauth` provides a way to
       * let the `AttendeeContainer` know it is ok to skip if the id matches.
       */
      shouldReauth: Type.Optional(Type.Boolean({default: true})),
    },
    options: {'$lcd-flow-ignore': true},
  }),
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Authenticated guest has been confirmed by Flux',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
        tags: Type.Array(Type.String(), {
          description: 'Tags associated with the attendee',
        }),
      }),
    },
    options: {'$lcd-flow-ignore': true},
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Authenticated guest could not be confirmed by Flux',
    meta: {
      reason: Type.Optional(
        Type.String({
          description: 'Indicates the reason the code could not be verified',
        })
      ),
    },
    options: {'$lcd-flow-ignore': true},
  }),
  SubscribesTo({
    topic: `${reactName}:verify-skipped`,
    description: 'Indicates the swid from #init was already logged in',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
        tags: Type.Array(Type.String(), {
          description: 'Tags associated with the attendee',
        }),
      }),
    },
    options: {'$lcd-flow-ignore': true},
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      attendeeTags: Type.String({
        description:
          'Comma separated list of tags associated with the attendee',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
    options: {title: 'Disney OneId: On Auth Success'},
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful login has occurred',
    meta: {error: Type.String()},
    options: {title: 'Disney OneId: On Auth Failure'},
  }),
  // #endregion Authentication
  // on logout
  PublishesTo({
    topic: `${reactName}:on-logout`,
    description: 'Indicates the user has logged out',
    options: {title: 'Disney OneId: On Logout'},
  }),
  PublishesTo({
    topic: `${reactName}:on-close`,
    description:
      'Indicates the Disney OneID authentication window has been closed',
    options: {title: 'Disney OneId: On Close'},
  }),
]);

export const ComponentDefinition = createComponentAdmin({
  id: 'e64d18ab-7dff-4b55-828f-1023ad3d5bc0',
  reactName,
  name,
  category: 'preset',
  description,
  defaultFieldData,
  instructions,
  schema,
  privateSchema,
  slotConfiguration: {},
  slug: reactName,
  uiSchema,
  version: 1,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
